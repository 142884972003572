import React from "react"
import { Row, Col } from "react-bootstrap"
import CustomButton from "../button"
import { useStaticQuery, graphql } from "gatsby"
import BlogList from "../blogList"
import { navigate } from "gatsby"

import "./insightSection.css"

const InsightSection = () => {
  const wpBlogData = useStaticQuery(graphql`
    {
      allStoryblokEntry(
        sort: { fields: [first_published_at], order: DESC }
        filter: { field_component: { eq: "post" } }
      ) {
        nodes {
          name
          full_slug
          id
          content
          first_published_at
        }
      }
    }
  `)
  return (
    <div className="insight-section-wrapper">
      <div className="insight-section-container">
        <Row className="insight-section-row">
          <h2 className="insight-section-tagline">Compare n Save insights</h2>
        </Row>
        <Row className="insight-section-row">
          <Col className="insight-section-slogan">
            <p>
              Get unique data, information and support from Compare n Save’s
              expert team.
            </p>
          </Col>
        </Row>
        <Row>
          <BlogList
            blogPreviews={wpBlogData.allStoryblokEntry.nodes}
            amount={4}
          />
        </Row>
        <div className="insight-button-row">
          <CustomButton
            variant="secondary"
            className="insight-section-button border-0"
            onClick={() => {
              navigate("/blogs")
            }}
          >
            Read more
          </CustomButton>
        </div>
      </div>
    </div>
  )
}

export default InsightSection
